<template>
  <div
    @click="onClickBtn"
    class="image-widget-container"
    :class="{ 'hide-if-bg': isBackground }"
    :style="textStyles"
  >
    <img
      @load="$emit('hideLoader')"
      :src="widget.configs.imageSrc"
      alt="image"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import setStyles from "@/utils/setStyles";
import clickAction from "@/utils/clickAction";

export default {
  name: "SvImageWidget",
  props: ["widgetIdx"],
  data() {
    return {
      btnDisabled: false,
      isBackground: false,
    };
  },
  created() {
    // ? set only first bigger then 80% image as body background
    const bgHeight = 575;
    const bgWidth = 320;
    const imgWidth = this.widget.width * this.widget.scaleX;
    const imgHeight = this.widget.height * this.widget.scaleY;
    if (imgHeight / bgHeight > 0.8 && imgWidth / bgWidth > 0.8) {
      const hasBg = document.body.style.getPropertyValue("--background-image");
      this.$emit("hideLoader");
      if (hasBg) return;
      this.isBackground = true;
      document.body.style.setProperty(
        "--background-image",
        `url(${this.widget.configs.imageSrc})`
      );
    }
  },
  computed: {
    ...mapGetters(["activeStep"]),
    widget() {
      return this.activeStep?.objects?.[this.widgetIdx];
    },
    textStyles() {
      const isConfigs = this.widget.configs && this.widget.configs.actionType;
      return {
        ...setStyles(this.widget),
        cursor: isConfigs !== "no action" && isConfigs ? "pointer" : "",
      };
    },
  },
  methods: {
    onClickBtn() {
      if (this.btnDisabled) return;
      this.btnDisabled = true;
      clickAction({
        widget: this.widget,
        widgetName: this.widgetIdx,
        widgetIdx: this.widgetIdx,
      }).then(() => {
        this.btnDisabled = false;
      });
    },
  },
};
</script>

<style scoped>
img {
  width: 100%;
  height: 100%;
}
.logo-widget-container {
  display: grid;
  justify-items: center;
  align-content: center;
}

.image-widget-container:after {
  transition: all 0.6s ease;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, var(--border-color) 100%, transparent 100%)
      top left no-repeat,
    linear-gradient(
        90deg,
        transparent calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)),
        transparent calc(var(--gap-size) / 2 + var(--dash-size))
      )
      top center repeat-x,
    linear-gradient(90deg, var(--border-color) 100%, transparent 100%) top right
      no-repeat,
    linear-gradient(0deg, var(--border-color) 100%, transparent 100%) top left
      no-repeat,
    linear-gradient(
        0deg,
        transparent calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)),
        transparent calc(var(--gap-size) / 2 + var(--dash-size))
      )
      center left repeat-y,
    linear-gradient(0deg, var(--border-color) 100%, transparent 100%) bottom
      left no-repeat,
    linear-gradient(90deg, var(--border-color) 100%, transparent 100%) bottom
      left no-repeat,
    linear-gradient(
        90deg,
        transparent calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)),
        transparent calc(var(--gap-size) / 2 + var(--dash-size))
      )
      bottom center repeat-x,
    linear-gradient(90deg, var(--border-color) 100%, transparent 100%) bottom
      right no-repeat,
    linear-gradient(0deg, var(--border-color) 100%, transparent 100%) top right
      no-repeat,
    linear-gradient(
        0deg,
        transparent calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)),
        transparent calc(var(--gap-size) / 2 + var(--dash-size))
      )
      center right repeat-y,
    linear-gradient(0deg, var(--border-color) 100%, transparent 100%) bottom
      right no-repeat;
  background-size: var(--dash-size) var(--border-weight),
    calc(var(--dash-size) + var(--gap-size)) var(--border-weight),
    var(--dash-size) var(--border-weight), var(--border-weight) var(--dash-size),
    var(--border-weight) calc(var(--dash-size) + var(--gap-size)),
    var(--border-weight) var(--dash-size);
}
@media screen and (max-width: 500px) {
  .hide-if-bg {
    display: none;
  }
}
</style>
